<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 비상대응훈련 상세 -->
          <c-card title="LBL0002663" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- [S]결재관련 버튼 -->
                <!-- sysApprovalRequestId: 상세조회시 관련 결재요청일련번호 확인 -->
                <!-- popupParam.isApprContent: 결재요청/결재처리 팝업의 component에 해당 페이지 호출시 true -->
                <!-- approvalStatusCd: 결재요청건에 대한 상태코드 -->
                <!-- popupParam.approvalDocType: TODO(결재할문서에서 상세페이지호출시) -->
                <c-appr-btn 
                  v-show="!isApprDisabled"
                  ref="appr-btn"
                  name="emergency-training-plan-appr-btn"
                  :editable="editable"
                  :approvalInfo="approvalInfo"
                  @beforeApprAction="saveDataAppr"
                  @callbackApprAction="approvalCallback"
                  @requestAfterAction="getDetail"
                />
                <!-- 삭제 -->
                <c-btn 
                  v-show="param.emergTrainingPlanId !='' && !disabled && !apprDisabled && editable" 
                  label="LBLREMOVE" 
                  icon="delete_forever" 
                  @btnClicked="remove" />
                <!-- 일괄저장 -->
                <c-btn
                  v-show="!disabled && !apprDisabled && editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="planResult"
                  :mappingType="saveType"
                  label="LBLSAVEALL"
                  icon="save"
                  @beforeAction="savePlan"
                  @btnCallback="saveCallback" 
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <!-- 훈련명 -->
                <c-text
                  required
                  :editable="editable"
                  :disabled="disabled || apprDisabled"
                  label="LBL0002657"
                  name="emergTrainingName"
                  v-model="planResult.emergTrainingName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
                <!-- 기간,일 -->
                <!-- true: 기간, false: 일 -->
                <c-checkbox
                  :disabled="disabled || apprDisabled"
                  :editable="editable"
                  :isFlag="true"
                  label="LBL0002885"
                  :trueLabel="$label('LBLPERIOD')"
                  :falseLabel="$label('LBL0002886')"
                  name="emergeTrainingRangeFlag"
                  v-model="planResult.emergeTrainingRangeFlag"
                  @datachange="rangechange"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  v-show="isDate"
                  :required="isDate"
                  :disabled="disabled || apprDisabled"
                  :editable="editable"
                  label="훈련기간"
                  :range="true"
                  type="date"
                  name="trainingPeriod"
                  v-model="trainingPeriod">
                </c-datepicker>
                <c-datepicker
                  v-show="!isDate"
                  :required="!isDate"
                  :disabled="disabled || apprDisabled"
                  :editable="editable"
                  label="훈련일"
                  type="date"
                  name="emergTrainingStartDate"
                  v-model="planResult.emergTrainingStartDate">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  required
                  :editable="editable && param.emergTrainingPlanId ==''"
                  :disabled="disabled && param.emergTrainingPlanId !=''"
                  codeGroupCd="EAP_EVAL_CLASS_CD"
                  itemText="codeName"
                  itemValue="code"
                  type="edit"
                  name="emergTrainingTypeCd"
                  label="훈련구분"
                  v-model="planResult.emergTrainingTypeCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="editable"
                  :disabled="disabled || apprDisabled"
                  label="훈련장소"
                  name="emergTrainingLoc"
                  v-model="planResult.emergTrainingLoc">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-dept
                  required
                  :editable="editable"
                  :disabled="disabled || apprDisabled"
                  label="훈련부서"
                  name="deptCd"
                  v-model="planResult.deptCd">
                </c-dept>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant 
                  required
                  :disabled="disabled || apprDisabled"
                  :editable="editable" 
                  type="edit" 
                  name="plantCd" 
                  v-model="planResult.plantCd"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <!-- 훈련시나리오명 -->
                <c-text
                  required 
                  :afterIcon="editable && !disabled && !apprDisabled ? [
                    { name: 'search', click: true, callbackName: 'searchTraningScenario', color: 'teal' },
                    { name: 'close', click: true, callbackName: 'removeTraningScenario', color: 'red' },
                  ] : null"
                  :editable="editable"
                  :disabled="true"
                  label="LBL0002609"
                  name="trainingScenarioName"
                  v-model="planResult.trainingScenarioName"
                  @searchTraningScenario="searchTraningScenario"
                  @removeTraningScenario="removeTraningScenario">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
          <c-table
            ref="tableScenario"
            title="훈련시나리오 목록"
            :columns="scenarioGrid.columns"
            :data="planResult.planScenarios"
            selection="multiple"
            :editable="editable && !disabled && !apprDisabled"
            :columnSetting="false"
            :gridHeight="gridHeight"
            :hideBottom="false"
            :isExcelDown="false"
            :usePaging="false"
            rowKey="trainingScenarioPlanId"
          >
            <!-- 버튼 영역 -->
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn 
                    v-show="editable && !disabled && !apprDisabled" 
                    label="추가" 
                    icon="add" 
                    @btnClicked="addPlanScenario" />
                <c-btn 
                    v-show="editable && !disabled && !apprDisabled && planResult.planScenarios.length > 0" 
                    label="LBLEXCEPT" 
                    icon="remove" 
                    :showLoading="false"
                    @btnClicked="removePlanScenarioItem" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 훈련참가자 목록 -->
          <c-table
            ref="tableAttend"
            title="LBL0002671"
            :columns="attendeeGrid.columns"
            :data="planResult.attendees"
            selection="multiple"
            :editable="editable && !disabled && !apprDisabled"
            :columnSetting="false"
            :gridHeight="gridHeight"
            :filtering="false"
            :hideBottom="false"
            :isExcelDown="false"
            :usePaging="false"
            rowKey="attendeeUserId"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <!-- 추가 -->
                <c-btn 
                  v-show="editable && !disabled && !apprDisabled" 
                  label="LBLADD" 
                  :showLoading="false"  
                  icon="add" @btnClicked="addItem" />
                <!-- 삭제 -->
                <c-btn 
                  v-show="editable && !disabled && !apprDisabled && planResult.attendees.length > 0" 
                  label="제외" 
                  :showLoading="false" 
                  icon="remove" 
                  @btnClicked="removeItem" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'emergencyTrainingPlan',
  props: {
    param: {
      type: Object,
      default: () => ({
        emergTrainingPlanId: '',
        emergTrainingStatusCd: '',
        trainingScenarioId: '',
      }),
    },
    isApprDisabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      planResult: {
        emergTrainingPlanId: '',
        plantCd: '',
        deptCd: '',
        emergTrainingName: '',
        emergTrainingStartDate: '',
        emergTrainingEndDate: '',
        emergTrainingTypeCd: null,
        emergTrainingLoc: '',
        trainingScenarioId: '',
        trainingScenarioName: '',
        emergTrainingStatusCd: '',
        emergeTrainingRangeFlag: 'N',
        planScenarios: [],
        deletePlanScenarios: [],
        attendees: [],
        regUserId: '',
        chgUserId: '',
        approvalPlanStatusCd: '',
        sysApprovalPlanRequestId: '',
      },
      attendeeGrid: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            // 부서
            label: 'LBLDEPT',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            // 이름
            label: 'LBLNAME',
            align: 'center',
            style: 'width:140px',
            userId: 'attendeeUserId',
            sortable: false,
          },
        ],
        data: [],
      },
      scenarioGrid: {
        columns: [
          {
            required: true,
            name: 'trainingTime',
            field: 'trainingTime',
            label: '진행시간',
            align: 'center',
            type: 'datetime',
            minuteStep: 10,
            style: 'width:80px',
            sortable: false,
          },
          {
            required: true,
            name: 'situationDevelopmentName',
            field: 'situationDevelopmentName',
            label: '상황전개',
            align: 'center',
            type: 'text',
            style: 'width:150px',
            sortable: false,
          },
          {
            required: true,
            name: 'generationLocationName',
            field: 'generationLocationName',
            label: '발생장소',
            align: 'center',
            type: 'text',
            style: 'width:150px',
            sortable: false,
          },
          {
            required: true,
            name: 'trainingContents',
            field: 'trainingContents',
            label: '훈련내용',
            type: 'text',
            align: 'left',
            style: 'width:350px',
            sortable: true,
          },
          {
            required: true,
            name: 'trainingManager',
            field: 'trainingManager',
            label: '담당자',
            type: 'text',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'trainingMaterial',
            field: 'trainingMaterial',
            label: '준비물',
            type: 'textarea',
            align: 'left',
            style: 'width:150px',
            sortable: true,
          },
          {
            required: true,
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            align: 'center',
            sortable: true,
            style: 'width:40px',
            type: 'number',
          },
        ],
        data: [],
      },
      editable: true,
      detailUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      saveUrl: '',
      saveType: 'POST',
      isSave: false,
      isApproval: false,
      getUrl: '',
      deleteUrl: '',
      isComplete: false,
      newDate: ['', ''],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      let height = window.innerHeight - 350;
      if (height < 450) {
        height = 450;
      }
      return String(height) + 'px';
    },
    apprDisabled() {
      return this.planResult.approvalPlanStatusCd === 'ASC0000001';
    },
    disabled() {
      return (this.editable && Boolean(this.planResult.emergTrainingStatusCd) && this.planResult.emergTrainingStatusCd !== 'ETSC000001');
    },
    isDate() {
      return this.planResult.emergeTrainingRangeFlag === 'Y'
    },
    trainingPeriod: {
      get() {
        if (this.planResult.emergTrainingStartDate && this.planResult.emergTrainingEndDate) {
          return [this.planResult.emergTrainingStartDate, this.planResult.emergTrainingEndDate]
        } else {
          return this.newDate;
        }
      },
      set(newDate) {
        if (newDate && newDate.length > 0) {
          this.trainingPeriod[0] = newDate[0]
          this.trainingPeriod[1] = newDate[1]
        } 
        this.newDate = newDate;
      }
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.planResult.sysApprovalPlanRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.planResult.approvalPlanStatusCd, 
        apprEditable: !this.disabled && this.param.emergTrainingPlanId, // 결재버튼 활성화 기준
        param: this.planResult, // 결재 param
        approvalUrl: transactionConfig.sop.eap.planResult.update.url, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000025', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          emergTrainingPlanId: this.param.emergTrainingPlanId,
          isApprContent: true,
          isApprDisabled: true
        },
        approvalRequestName: '비상대응훈련계획 ['+this.planResult.emergTrainingName+']', // 결재요청명 (문서 title)
        approvalConnId: this.param.emergTrainingPlanId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.eap.planResult.plan.get.url;
      this.saveUrl = transactionConfig.sop.eap.planResult.insert.url;
      this.deleteUrl = transactionConfig.sop.eap.planResult.delete.url;
      this.getItemUrl = selectConfig.sop.eap.training.plan.getItem.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.param.emergTrainingPlanId) {
        this.$http.url = this.$format(this.detailUrl, this.param.emergTrainingPlanId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.planResult = _result.data;
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        this.planResult.regUserId = this.$store.getters.user.userId;
      }
    },
    searchTraningScenario() {
      this.popupOptions.title = '훈련 시나리오 검색';
      this.popupOptions.param = {
        type: 'single',
        trainingScenarioId: this.planResult.trainingScenarioId,
        deptCd: this.planResult.deptCd
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/eap/scenario/emergencyScenarioEvalPop'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeTraningScenarioPopup;
    },
    /* eslint-disable no-unused-vars */ 
    closeTraningScenarioPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.planResult.planScenarios = [];
        this.$http.url = this.$format(selectConfig.sop.eap.emergTraining.get.url, data[0].trainingScenarioId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.planResult.trainingScenarioName = _result.data.trainingScenarioName
          this.planResult.trainingScenarioId = _result.data.trainingScenarioId
          if (_result.data.scenarios) {
            this.$_.forEach(_result.data.scenarios, _item =>{
              this.planResult.planScenarios.push({
                emergTrainingPlanId: this.param.emergTrainingPlanId,
                trainingScenarioPlanId: uid(),
                situationDevelopmentName: _item.situationDevelopmentName,
                generationLocationName: _item.generationLocationName,
                trainingContents: _item.trainingContents,
                trainingManager: _item.trainingManager,
                trainingMaterial: _item.trainingMaterial,
                sortOrder: _item.sortOrder,
                regUserId: this.$store.getters.user.userId,
                chgUserId: this.$store.getters.user.userId,
                editFlag: 'C',
              })
            })
          }
        },);
      }
    },
    removeTraningScenario() {
      this.planResult.trainingScenarioName = '';
      this.planResult.planScenarios = [];
    },
    savePlan() {
      if (this.param.emergTrainingPlanId) {
        this.saveUrl = transactionConfig.sop.eap.planResult.update.url;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.eap.planResult.insert.url;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          if (this.$comm.validTable(this.scenarioGrid.columns, this.planResult.planScenarios)) {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: 'MSGSAVE', // 저장하시겠습니까?
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.planResult.regUserId = this.$store.getters.user.userId
                this.planResult.chgUserId = this.$store.getters.user.userId

                // 일을 기간으로 체크했을때 기간으로 저장하는 부분
                if (this.planResult.emergeTrainingRangeFlag === 'Y') {
                  if (this.trainingPeriod && this.trainingPeriod.length > 0) {
                    this.planResult.emergTrainingStartDate = this.trainingPeriod[0];
                    this.planResult.emergTrainingEndDate = this.trainingPeriod[1];
                  }
                }

                this.isSave = !this.isSave
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.param.emergTrainingPlanId = _result.data
      this.$emit('insertTraningInfo', _result.data);
      this.getDetail();
    },
    /* eslint-disable no-unused-vars */ 
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.param.emergTrainingPlanId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');

            this.$emit('emitStep', {
              name: 'closePopup',
              param: {},
            })
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    addItem() {
      this.popupOptions.title = "LBL0002678"; // 훈련참가자 검색
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '60%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeItemPopup;
      this.$refs['tableAttend'].$refs['compo-table'].clearSelection();
    },
    closeItemPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.planResult.attendees, {
            attendeeUserId: _item.userId,
          });
          if (index === -1) {
            this.planResult.attendees.splice(0, 0, {
              emergTrainingPlanId: this.param.emergTrainingPlanId,
              attendeeUserId: _item.userId,
              userName: _item.userName,
              deptName: _item.deptName,
              editFlag: 'C',
            })
          }
        })
      }
    },
    removeItem() {
      let selectData = this.$refs['tableAttend'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.planResult.attendees = this.$_.reject(this.planResult.attendees, item)
          this.$refs['tableAttend'].$refs['compo-table'].clearSelection();
        })
      }
    },
    addPlanScenario() {
      this.planResult.planScenarios.push({
        trainingScenarioId: this.param.emergTrainingPlanId,
        trainingScenarioPlanId: uid(),
        trainingTime: '',
        situationDevelopmentName: '',
        generationLocationName: '',
        trainingContents: '',
        trainingManager: '',
        trainingMaterial: '',
        sortOrder: 0,
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
    removePlanScenarioItem() {
      let selectData = this.$refs['tableScenario'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.planResult.deletePlanScenarios) {
            this.planResult.deletePlanScenarios = []
          }
          if (this.$_.findIndex(this.planResult.deletePlanScenarios, { trainingScenarioPlanId: item.trainingScenarioPlanId }) === -1
            && item.editFlag !== 'C') {
              this.planResult.deletePlanScenarios.push(item)
          }
          this.planResult.planScenarios = this.$_.reject(this.planResult.planScenarios, item)
        })
      }
    },
    rangechange() {
      this.planResult.emergTrainingStartDate = '';
      this.planResult.emergTrainingEndDate = '';
      if (this.planResult.emergeTrainingRangeFlag === 'Y') {
        this.planResult.emergTrainingStartDate = '';
      } else {
        this.trainingPeriod = ['', ''];
      }
    },
    /* eslint-disable no-unused-vars */
    saveDataAppr() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.planResult.regUserId = this.$store.getters.user.userId
          this.planResult.chgUserId = this.$store.getters.user.userId

          let promises = [
            {
              func: this.approvalValue
            },
          ];
          this.$comm.orderedPromise(promises);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
  }
};
</script>
